import {LitElement, html, customElement} from '../../node_modules/lit-element';
import {property} from "lit/decorators.js";

@customElement('nonstop-header')
export class HeaderElement extends LitElement {
    @property({type: String})
    selectedView: string = "";

    @property({type: Boolean})
    showSearch: boolean = false;

    protected override createRenderRoot() {
        return this;
    }

    override render() {
        return html`
            <header class="header header_style_01">
                <nav class="megamenu navbar navbar-default">
                    <div class="container-fluid">
                        <div class="navbar-header">
                            <button type="button" class="navbar-toggle collapsed" data-toggle="collapse"
                                    data-target="#navbar"
                                    aria-expanded="false" aria-controls="navbar">
                                <span class="sr-only">Toggle navigation</span>
                                <span class="icon-bar"></span>
                                <span class="icon-bar"></span>
                                <span class="icon-bar"></span>
                            </button>
                            <a class="navbar-brand" href="index.html">
                                <img src="../../images/logo.png"/>
                            </a>

                        </div>
                        <div id="navbar" class="navbar-collapse collapse">
                            <ul class="nav navbar-nav navbar-right">
                                <li><a class=${(this.selectedView === "home" ? "active" : "")}
                                       href="index.html">Home</a></li>
                                <li><a class=${(this.selectedView === "buy" ? "active" : "")} href="buy.html">Buy</a>
                                </li>
                                <li><a class=${(this.selectedView === "sell" ? "active" : "")} href="sell.html">Sell</a>
                                </li>
                                <li><a class=${(this.selectedView === "rent" ? "active" : "")} href="rent.html">Rent</a>
                                </li>
                                <li><a class=${(this.selectedView === "manage" ? "active" : "")} href="manage.html">Manage</a>
                                </li>
                                <li><a class=${(this.selectedView === "about" ? "active" : "")} href="about.html">About </a></li>
                                <li><a class=${(this.selectedView === "contact" ? "active" : "")} href="contact.html">Contact</a>
                                </li>
                                <!--                    <li class="social-links"><a href="#"><i class="fa fa-twitter global-radius"></i></a></li>-->
                                <!--                <li class="social-links"><a href="#"><i class="fa fa-facebook global-radius"></i></a></li>-->
                                <li class="social-links"><a href="https://www.instagram.com/nonstoprealestateau/"
                                                            target="_blank"><i
                                        class="fa fa-instagram global-radius"></i></a></li>
                                <!--                    <li class="social-links"><a href="#"><i class="fa fa-linkedin global-radius"></i></a></li>-->
                                <li class="social-links dropdown-toggle" id="wechatDropdown" data-toggle="dropdown"
                                    aria-haspopup="true" aria-expanded="false"><a href="#"><i
                                        class="fa fa-wechat global-radius"></i></a></li>

                                <form action="#" class="p-color-bg dropdown-menu tran3s"
                                      aria-labelledby="wechatDropdown">
                                    <img id="wechat" src="../../images/wechat-qr-code.png"/>
                                    <!--                  <button type="button" class="p-color-bg"><i class="fa fa-search" aria-hidden="true"></i></button>-->
                                </form>

                                ${
                                        this.showSearch ?
                                                html`
                                                    <li class="search-option">
                                                        <button class="search tran3s dropdown-toggle"
                                                                id="searchDropdown" data-toggle="dropdown"
                                                                aria-haspopup="true" aria-expanded="false"><i
                                                                class="fa fa-search"
                                                                aria-hidden="true"></i></button>
                                                        <form action="#" class="p-color-bg dropdown-menu tran3s"
                                                              aria-labelledby="searchDropdown">
                                                            <input type="text" placeholder="Search....">
                                                            <button type="button" class="p-color-bg"><i
                                                                    class="fa fa-search" aria-hidden="true"></i>
                                                            </button>
                                                        </form>
                                                    </li>
                                                ` :
                                                ''
                                }
                            </ul>
                        </div>
                    </div>
                </nav>
            </header>
        `;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        'nonstop-header': HeaderElement;
    }
}
